/* eslint-disable max-len */

VisitWidget.SelfGuidedListController = class SelfGuidedListController extends (
  VisitWidget.TourListController
) {
  getTours(params, callback) {
    params.type = "SelfGuided";
    params.exclusive_category_id = this.menuItem.selectedExclusiveCategoryId;
    return VisitWidget.Tour.query(params, callback);
  }
};
